<template>
  <div class="page">
    <div class="heading">
      <v-toolbar dense :flat="flatapp">
        <v-btn icon @click="menulink">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-breadcrumbs
          :items="crumbs"
          style="padding: 14px 20px"
          class="capitalize"
        >
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>
        <!-- <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn text color="success" v-on="on">
              <v-icon size="25" dark>mdi-bell-outline</v-icon>
              <v-badge
                bordered
                content="3"
                overlap
                style="margin: 5px 10px 0px 10px"
              ></v-badge>
            </v-btn>
          </template>
          <span>Request Response Notification</span>
        </v-tooltip> -->
      </v-toolbar>
      <v-divider></v-divider>
    </div>

    <div class="body">
      <v-text-field
        v-model="searchme"
        filled
        rounded
        label="Filter to search provider"
      ></v-text-field>

      <v-data-iterator :items-per-page="8" :items="userlist" :search="searchme">
        <template v-slot:default="props">
          <v-row>
            <v-col
              v-for="item in props.items"
              :key="item.id"
              cols="12"
              sm="4"
              md="4"
              lg="4"
            >
              <v-card flat outlined>
                <v-img
                  height="200"
                  :src="pix(item.provider_pix)"
                  :title="item.description"
                ></v-img>
                <v-card-text style="padding: 5px 10px">
                  <div class="data_list">
                    <ul>
                      <li>
                        <div class="mytitle">name</div>
                        <div class="content">{{ item.client }}</div>
                        <div class="clearall"></div>
                      </li>
                      <li>
                        <div class="mytitle">comment</div>
                        <div class="comment scroll">{{ item.comment }}</div>
                        <div class="clearall"></div>
                      </li>
                      <li>
                        <div class="mytitle">Date</div>
                        <div class="comment">{{ item.updated | myDating }}</div>
                        <div class="clearall"></div>
                      </li>
                      <li>
                        <div class="mytitle">rating</div>
                        <div class="content">
                          <v-rating
                            small
                            readonly
                            :value="item.rating"
                          ></v-rating>
                        </div>
                        <div class="clearall"></div>
                      </li>
                    </ul>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </v-data-iterator>
    </div>
    <v-snackbar
      :color="snack.color"
      v-model="snack.bar"
      :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import WinScroll from "../../mixins/windowscroll";
import Restful from "@/services/RestFul";
// import RatingNewOne from "../../components/rating/newone.vue";
// import RatingEdit from "../../components/rating/edit.vue";

export default {
  // components: { RatingNewOne, RatingEdit },
  mixins: [WinScroll("position")],
  data() {
    return {
      crumbs: [
        {
          exact: true,
          link: true,
          text: "Dashboard",
          to: { name: "medics.dash" },
        },
      ],
      snack: {
        color: "info",
        multiLine: true,
        bar: false,
        text: "Processing ... ",
      },
      searchme: null,
      newDialog: false,
      editDialog: false,
      loading: false,
      subscribersList: [],
      userlist: [],
      showDetail: false,
      searching: {
        id: null,
        fullname: null,
        biz: "",
        isAvailable: false,
        myRating: {},
      },
      create: {
        comment: null,
        rating: 0,
      },
    };
  },
  watch: {
    "searching.id": function (a) {
      if (a === null) {
        this.searching.biz = "";
      } else {
        this.searching.biz = this.searching.id.business_name;
      }
    },
  },
  computed: {
    flatapp: function () {
      if (this.position[1] > 30) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.list();
    this.subscribers();
  },
  methods: {
    menulink() {
      this.$emit("sidebar");
    },
    list() {
      this.loading = true;
      let self = this;
      Restful.ratings.subscribers
        .subscriberList()
        .then((response) => {
          console.log(response);
          this.userlist = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    pix(img) {
      if (img === "") {
        let pix = require("../../assets/images/tempix.webp");
        return pix;
      } else {
        return img;
      }
    },
    subscribers() {
      Restful.medics.patient.request
        .subscribers()
        .then((response) => {
          console.log(response);
          this.subscribersList = response.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    search() {
      let p = this.searching.id;
      console.log(p.subscribersid);
      this.searching.fullname = p.fullname;
      this.check();
    },
    check() {
      let subscribersid = this.searching.id.subscribersid;
      let data = { subscribersid };
      Restful.ratings.subscribers
        .check(data)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.searching.myRating = response.data;
            this.searching.isAvailable = true;
          } else {
            this.searching.isAvailable = false;
          }
          this.showDetail = true;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    closeNew(p) {
      this.newDialog = p.state;
    },
    submitNew(p) {
      console.log(p.data);
      this.newDialog = p.state;
      this.snack.bar = true;
      this.snack.color = "primary";
      this.snack.text = "Processing ...";

      let self = this;
      Restful.ratings.subscribers
        .create(p.data)
        .then((response) => {
          console.log(response);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.list();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
    },
    closeEdit(p) {
      this.editDialog = p.state;
    },
    submitEdit(p) {
      console.log(p);
      this.newDialog = p.state;
      this.snack.bar = true;
      this.snack.color = "primary";
      this.snack.text = "Processing ...";

      let self = this;
      Restful.ratings.subscribers
        .update(p.id, p.data)
        .then((response) => {
          console.log(response);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.list();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.editDialog = p.state;
    },
  },
};
</script>
